import React, { useState } from 'react'

export const MaxDimensions = ({ row, setRow }) => {
  return <>
    <input
      type={'number'}
      value={row.length}
      onChange={(e) => setRow({...row, length: e.target.value})}
      placeholder='enter (optional)'
    />
    &nbsp;X&nbsp;
    <input
      type={'number'}
      value={row.width}
      onChange={(e) => setRow({...row, width: e.target.value})}
      placeholder='enter (optional)'
    />
    &nbsp;X&nbsp;
    <input
      type={'number'}
      value={row.height}
      onChange={(e) => setRow({...row, height: e.target.value})}
      placeholder='enter (optional)'
    />
  </>
}
