import React, { useState } from 'react'

export const LongestSide = ({ value, onValueChange }) => {
  return (
    <input
      type="number"
      value={value}
      placeholder='enter (optional)'
      onChange={(e) => onValueChange(e.target.value)}
    />
  )
}
