import React, { useState, useEffect } from 'react'
import CurrencyInput from 'react-currency-input-field'

export const TableInput = ({
  value,
  placeholder,
  prefix,
  suffix,
  onValueChange,
  highlightErrors = false,
  required = false
}) => {
  return (
    <CurrencyInput
      prefix={prefix}
      suffix={suffix}
      placeholder={placeholder}
      defaultValue={value}
      value={value}
      onValueChange={onValueChange}
      disableGroupSeparators={true}
      allowDecimals={true}
      allowNegativeValue={false}
      className={highlightErrors && !value ? 'error' : ''}
    />
  )
}
