import React, { useState } from 'react'

export const CarrierName = ({ value, onValueChange }) => {
  return (
    <input
      type="text"
      value={value}
      placeholder='select your carrier'
      onChange={(e) => onValueChange(e.target.value)}
    />
  )
}
