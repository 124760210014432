import React, { useState, useEffect } from 'react'

export const Weight = ({
  value,
  onValueChange,
  highlightErrors,
}) => {
  const options = [5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70]

  return (
    <select
      className={highlightErrors && !value ? 'error' : ''}
      onChange={(e) => onValueChange(e.target.value)}
    >
      <option value="" disabled selected hidden>select weight</option>
      {options.map((option) => {
        return (
          <option
            value={option}
            selected={option == value}
          >
            Up to {option} KG
          </option>
        )
      })}
    </select>
  )
}
